import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Highlight } from 'react-instantsearch-dom';

const StyledLink = styled(Link)`
  list-style: none;

  & mark {
    background-color: ${({ theme }) => theme.color.active};
    color: ${({ theme }) => theme.color.main};
  }
`;

const StyledArticle = styled.article`
  display: flex;
  align-items: center;
  margin: 2rem;
  padding: 1rem;
  border-radius: 2rem 2rem 0 0;
  transition: all 0.25s;
  border-bottom: 1px solid ${({ theme }) => theme.color.activebg};
  :hover {
    background-color: ${({ theme }) => theme.color.devider};
  }

  & > div {
    width: 100%;
  }
`;

const StyledPostMeta = styled.p`
  color: ${({ theme }) => theme.color.darkFont};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-style: italic;
`;

const SearchLink = ({ data, hit }) => {
  return (
    <StyledLink to={`/blog${hit.fields.slug}`}>
      <StyledArticle>
        <div>
          <h5>
            <Highlight hit={hit} attribute='frontmatter.title' tagName='mark' />
          </h5>
          <p>
            <Highlight
              hit={hit}
              attribute='frontmatter.description'
              tagName='mark'
            />
          </p>
          <StyledPostMeta>
            {}
            Tagi:{' '}
            <Highlight
              hit={hit}
              attribute='frontmatter.tags'
              tagName='mark'
            />{' '}
            || autor:{' '}
            <Highlight
              hit={hit}
              attribute='frontmatter.authors[0].author'
              tagName='mark'
            />{' '}
            <Highlight hit={hit} attribute='frontmatter.date' tagName='mark' />
          </StyledPostMeta>
        </div>
      </StyledArticle>
    </StyledLink>
  );
};

export default SearchLink;
