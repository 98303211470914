import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';

import SEO from 'components/utils/seo';
import Layout from 'components/utils/layout';
import GridView from 'components/utils/gridView';
import BlogIndex from 'components/modules/blogIndex';
import AlgoliaSearchbar from 'components/organisms/algolia-searchbar';
import StyledPost from 'components/modules/styledPost';

const StyledSection = styled.section`
  display: grid;
  grid-auto-rows: minmax(min-content, 40rem);
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10rem;

  ${({ theme: { mq } }) => mq.tablet} {
    grid-template-columns: 1fr;
  }
`;

const StyledTop = styled.div`
  position: relative;
  grid-column: 2 / 4;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  ${({ theme: { mq } }) => mq.tablet} {
    margin-top: 7rem;
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    grid-column: 1 / -1;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  height: 100%;
  min-height: 40rem;
  margin: 2.55rem;
  :nth-of-type(3n + 1) {
    grid-column: 1/-1;
  }
  & > * {
    height: 100%;
  }

  ${({ theme: { mq } }) => mq.tablet} {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledBlogIndex = styled(BlogIndex)`
  margin-top: 5rem !important;
`;

const BlogPage = ({ data, pageContext }) => {
  const posts = data.allMdx.edges;

  return (
    <Layout>
      <SEO title='Blog' image='logo' />
      <GridView page='blog'>
        <StyledTop>
          <AlgoliaSearchbar />
        </StyledTop>
        <StyledSection>
          {posts.map(post => (
            <StyledLink
              key={post.node.frontmatter.title}
              to={`/blog${post.node.fields.slug}`}
            >
              <StyledPost post={post} enviroment='blog' />
            </StyledLink>
          ))}
        </StyledSection>
        <StyledBlogIndex pageContext={pageContext} />
      </GridView>
    </Layout>
  );
};

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            authors {
              author
            }
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;

export default BlogPage;
