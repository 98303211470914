import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Button from 'components/atoms/button';
import Excerpt from 'components/atoms/excerpt';

import { useImg } from 'hooks/useImg';

const Wrapper = styled.article.attrs(props => ({
  className: 'recent-post',
}))`
  border-radius: 2rem;
  display: grid;
  grid-template-rows: minmax(20rem, 30rem) min-content 1fr min-content min-content;
  grid-gap: 1rem;
  border: 1px solid ${({ theme }) => theme.color.devider};
  background-color: ${({ theme: { color } }) => color.mainLighter};
  box-shadow: ${({ theme: { shadow } }) => shadow.borderShadow2};

  & > *:not(:first-child) {
    margin: 0 1rem;
  }

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  h5 {
    text-align: left !important;
    padding-left: 2.5rem;
    ${({ theme: { mq } }) => mq.tablet} {
      font-size: 20px;
      line-height: 1.3;
    }
  }

  ${({ theme: { mq } }) => mq.bigTablet} {
    width: 100%;
  }
  ${({ theme: { mq } }) => mq.tablet} {
    width: 80%;
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    width: 100%;
  }
`;

const StyledImg = styled(Img)`
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
`;

const PostInfo = styled.p`
  font-style: italic;
  font-size: 1.5rem;
  padding-left: 2.5rem;
  color: ${({ theme }) => theme.color.activebg};
  margin-bottom: auto;
  ${({ theme: { mq } }) => mq.tablet} {
    font-size: 12px;
  }
`;

const StyledButton = styled.button`
  padding: 0 0 2rem 2.5rem;
  border: none;
  background: none;
  justify-self: left;
`;

const StyledPost = ({ post, enviroment }) => {
  const { defaultImg } = useImg();
  const { node: content } = post;
  return (
    <Wrapper key={`${content.frontmatter.title}`}>
      <StyledImg
        fluid={
          content.frontmatter.image
            ? content.frontmatter.image.childImageSharp.fluid
            : defaultImg
        }
      />
      <h5>{content.frontmatter.title}</h5>
      <Excerpt>{content.frontmatter.description}</Excerpt>
      <PostInfo>
        {content.frontmatter.authors.map(author => author.author)},{' '}
        {content.frontmatter.date.substring(0, 10)} ||{' '}
        {content.frontmatter.tags.map(tag => `#${tag} `)}{' '}
      </PostInfo>
      {enviroment !== 'blog' && (
        <StyledButton>
          <Button to={`/blog${content.fields.slug}`}>czytaj dalej</Button>
        </StyledButton>
      )}
    </Wrapper>
  );
};

export default StyledPost;
