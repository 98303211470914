import React from 'react';
import { Link } from 'gatsby';

import styled from 'styled-components';

const Wrapper = styled.div`
  bottom: 6rem;
  margin: 10rem auto 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  a {
    padding: 0rem 2rem;
    background-color: #00004810;
    :focus,
    :active {
      border: 2px dashed ${({ theme }) => theme.color.active} !important;
    }
    &:not(:last-child) {
      border-right: 1px solid #00223311;
    }

    ${({ theme: { mq } }) => mq.tablet} {
      padding: 1rem 3.5rem;
    }
  }
`;

const StyledIndexNav = styled.nav`
  border-radius: 5rem;
`;

const PrevButton = styled(Link)`
  pointer-events: ${({ pagenumber }) => (pagenumber === 1 ? `none` : ` auto`)};
  color: ${({ pagenumber, theme }) =>
    pagenumber === 1 ? `gray` : theme.color.active};
`;
const NextButton = styled(Link)`
  pointer-events: ${({ pagenumber, numberofpages }) =>
    pagenumber === numberofpages ? `none` : ` auto`};
  color: ${({ pagenumber, numberofpages, theme }) =>
    pagenumber === numberofpages ? `gray` : theme.color.active};
`;

const CurrentLink = styled(Link)`
  pointer-events: none;
  background-color: ${({ theme }) => theme.color.active} !important;
  color: ${({ theme }) => theme.color.main} !important;
`;

const pageIndex = ({ pageContext }) => {
  const {
    previousPagePath,
    nextPagePath,
    humanPageNumber,
    numberOfPages,
  } = pageContext;

  return (
    <Wrapper>
      <StyledIndexNav>
        <PrevButton
          pagenumber={humanPageNumber}
          numberofpages={numberOfPages}
          to={previousPagePath}
        >
          &larr;
        </PrevButton>

        {/* Link to current page - 2 */}
        {humanPageNumber - 2 > 0 && (
          <Link
            to={`/blog/${humanPageNumber - 2 === 1 ? '' : humanPageNumber - 2}`}
          >
            {humanPageNumber - 2}
          </Link>
        )}

        {/* Link to previous page */}
        {humanPageNumber - 1 > 0 && (
          <Link to={previousPagePath}>{humanPageNumber - 1}</Link>
        )}

        {/* Current Page */}
        <CurrentLink to={`/blog/${humanPageNumber}`}>
          {humanPageNumber}
        </CurrentLink>

        {/* Link to next page */}
        {humanPageNumber + 1 < numberOfPages + 1 && (
          <Link to={nextPagePath}>{humanPageNumber + 1}</Link>
        )}

        {/* Link to current page + 2 */}
        {humanPageNumber + 2 < numberOfPages && (
          <Link
            to={`/blog/${
              humanPageNumber + 2 === numberOfPages ? '' : humanPageNumber + 2
            }`}
          >
            {humanPageNumber + 2}
          </Link>
        )}

        <NextButton
          pagenumber={humanPageNumber}
          numberofpages={numberOfPages}
          to={nextPagePath}
        >
          &rarr;
        </NextButton>
      </StyledIndexNav>
    </Wrapper>
  );
};

export default pageIndex;
